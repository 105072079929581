import React from 'react'
import SbEditable from 'storyblok-react'
import {
  Box,
  Typography,
  List,
  ListItemText,
  useMediaQuery,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import renderBlok from '@renderBlok'
import renderBloks from '@renderBloks'
import ACC_THEME from '@themes'

const useStyles = makeStyles((theme) => ({
  sidebar: {
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: ACC_THEME.palette.common.white,
    padding: '35px 32px',
    [ACC_THEME.breakpoints.down('md')]: {
      color: ACC_THEME.palette.common.white,
    },
  },
  sidebarItemsContainer: {
    color: ACC_THEME.palette.text.grey3,
    display: 'flex',
    width: '100%',
    justifyContent: 'space-between',
    [theme.breakpoints.up('lg')]: {
      flexDirection: 'column',
      minWidth: '160px',
    },
  },
  itemContainer: {
    width: '100%',
  },
  platformLinksContainer: {
    color: ACC_THEME.palette.text.grey3,
  },
  heading: {
    fontSize: '14px',
    marginBottom: '1rem',
  },
  lifeCycleHeadline: {
    textTransform: 'uppercase',
    marginBottom: '1rem',
    '&>span': {
      color: ACC_THEME.palette.text.grey3,
      fontSize: '0.95rem',
    },
  },
}))

const TopNavLeftSidebarMenu = (props) => {
  const { items, heading, overviewLink, platformLinks } = props.blok
  const classes = useStyles(props)
  const handHeldDevice = useMediaQuery(ACC_THEME.breakpoints.down('sm'))

  return (
    <SbEditable key={props.blok._uid} content={props.blok}>
      {!handHeldDevice ? (
        <Box className={classes.sidebar}>
          {!!heading?.length && (
            <Typography className={classes.heading}>{heading}</Typography>
          )}
          <Box className={classes.sidebarItemsContainer}>
            {items.map((item) => (
              <Box key={item._uid} className={classes.itemContainer}>
                {renderBlok(item)}
              </Box>
            ))}
          </Box>
          {platformLinks && platformLinks.length > 0 && (
            <Box my={2} className={classes.platformLinksContainer}>
              {renderBloks(platformLinks)}
            </Box>
          )}
        </Box>
      ) : (
        <List component="div" disablePadding>
          <ListItemText
            primary={heading}
            className={classes.lifeCycleHeadline}
          />
          {renderBloks(overviewLink)}
          {renderBloks(items, { isMobileNavContent: true })}
          {renderBloks(platformLinks)}
        </List>
      )}
    </SbEditable>
  )
}

export default TopNavLeftSidebarMenu
